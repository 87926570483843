// -- Custom imageBackground plugin
// --------------------------------

(function($) {

    $.imageBackground = function(element, options) {

        var defaults = {
			position : 'bottom center'
        };

        var plugin = this;

        plugin.opts = {}; 

        var $container			= $(element),
			$image				= $container.find('> img'),
			$image_url			= $image.attr('src');


        plugin.init = function() {

            // Stop if no image is found
            if ( $image.length === 0 ) {
                return;
            }
            plugin.opts = $.extend({}, defaults, options);
            plugin._construct();
        };

		plugin._construct = function() {

			plugin.setup();
			// plugin.update();
		};

        plugin.updateSource = function() {

            $image_url = $image.attr('src');

            $container.css({
                'background-image' : 'url(' + $image_url + ')',
            });

        };

		plugin.setup = function() {

			// if( $image_url ) {
				$container.css({
					'background-image' 		: 'url(' + $image_url + ')',
					'background-repeat' 	: 'no-repeat',
					'background-size'		: 'cover',
					'background-position'	: plugin.opts.position
				});
			// }

			$image.css('opacity', 0);
		};

        plugin.init();

    };

    $.fn.imageBackground = function(options) {

		return this.each(function() {
            if (undefined === $(this).data('imageBackground')) {
                var plugin = new $.imageBackground(this, options);
                $(this).data('imageBackground', plugin);
            }
        });

    };

})(jQuery);
